/* tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Jost Fonts */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

/* Inria Sans Fonts  */
@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

/* Lora Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');


/* body */
* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  line-height: 1.6;
}

a {
  color: inherit;
  text-decoration: none;
}

.mainbodylayout{
  max-width: 1440px;
  margin: auto;
}

.fontjost{
  font-family: "Jost", sans-serif;
}

.fontinriasans{
  font-family: "Inria Sans", sans-serif;
}

.fontlora{
  font-family: "Lora", serif;
}