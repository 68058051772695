.herosectiongrid{
    display: grid;
    grid-template-columns: 1fr 1.2fr 0.8fr;
    gap: 2rem;
}

@media only screen and (max-width: 1000px){
    .herosectiongrid{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .herosectiongrid > div:nth-child(3) {
        grid-column: span 2;
      }
}
@media only screen and (max-width: 600px){
    .herosectiongrid{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .herosectiongrid > div:nth-child(3) {
        grid-column: span 1;
      }
}
