.navbaranimationmobile{
  animation: slideInFromTop 0.55s ease-in-out;
}
.navbaranimation {
  animation: slideInFromNewTop 0.55s ease-in-out;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromNewTop {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}