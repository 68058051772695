.blogpage h1,
.blogpage h2,
.blogpage h3,
.blogpage h4,
.blogpage h5,
.blogpage h6 {
  color: #333;
}

.blogpage h1 {
  font-size: clamp(1.5rem,2.5vw,3rem);
}
.blogpage h2 {
  margin: 1rem 0;
  font-size: clamp(1.2rem, 2vw, 2rem);
}

.blogpage h3 {
  margin: 0.5rem 0;
  font-size: clamp(1.1rem, 1.8vw, 1.75rem);
}

.blogpage h4 {
  margin: 0.5rem 0;
  font-size: clamp(1rem, 1.6vw, 1.5rem);
}

.blogpage h5 {
  font-size: clamp(0.9rem, 1.4vw, 1.25rem);
}

.blogpage p {
  color: #4a4a4a;
}
.blogpage ol{
  list-style-type: decimal;
  padding-left: 1rem;
}
.blogpage ul{
  list-style-type: disc;
  padding-left: 1rem;
}
